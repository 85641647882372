<template>
  <div>
    <SmsTemplateAdd
      :initial-sms-template.sync="smsTemplate"
      :sms-template-value.sync="smsTemplate" />
  </div>
</template>

<script>
import SmsTemplateAdd from '@/views/SmsTemplate/components/SmsTemplateAdd.vue'

export default {
  components: {
    SmsTemplateAdd
  },
  data () {
    return {
      smsTemplate: {
        templateName: '',
        message: ''
        // remark: ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
