<template>
  <div>
    <b-card
      @hidden="resetForm()">
      <template #default="{}">
        <ValidationObserver
          #default="{ handleSubmit }"
          ref="SmsTemplateAddForm">
          <b-form
            @submit.prevent="handleSubmit(addSmsTemplate)"
            @reset.prevent="resetForm()">
            <p class="text--weight">
              Add SMS Template
            </p>
            <b-row>
              <b-col
                sm="12"
                md="5">
                <TextFieldInput
                  v-model="smsTemplate.templateName"
                  :rules="{ required: false }"
                  name="หัวข้อ"
                  label="หัวข้อ"
                  placeholder="หัวข้อ"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="12">
                <TextAreaInput
                  v-model="smsTemplate.message"
                  :name="`message`"
                  :label="`ข้อความ`" />
              </b-col>
              <!-- <b-col
                sm="12"
                md="12">
                <TextAreaInput
                  v-model="smsTemplate.remark"
                  :name="`remark`"
                  :label="`Remark`" />
              </b-col> -->
            </b-row>
            <div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class=""
                variant="success"
                @click="addSmsTemplate()">
                <span class="align-middle"> Save </span>
              </b-button>
            </div>
          </b-form>
        </ValidationObserver>
      </template>
    </b-card>
  </div>
</template>

<script>
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
import TextAreaInput from '@/components/Form/TextAreaInput.vue'
import SmsTemplateProvider from '@/resources/SmsTemplateProvider'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const SmsTemplateService = new SmsTemplateProvider()

export default {
  components: {
    TextFieldInput,
    TextAreaInput
  },
  props: {
    initialSmsTemplate: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    smsTemplate: {
      get () {
        return this.initialSmsTemplate
      },
      set () {
        this.$emit('update:sms-template-value')
      }
    }
  },
  methods: {
    resetForm () {
      this.$refs.SmsTemplateAddForm.reset()
    },
    async addSmsTemplate () {
      try {
        const payload = { ...this.smsTemplate }
        await SmsTemplateService.create(payload)
        this.$router.push({ name: 'sms-template' })
        this.resetForm()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Void Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 SMS Template has been void'
          }
        })
      } catch (error) {
        console.log('error', error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '😞 SMS Template has been Failed'
          }
        })
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.text--weight {
  font-weight: 500 !important;
}

</style>
